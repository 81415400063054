var lightTheme = {
  "--textColor": '#333333',
  "--backgroundColor": '#ffffff',
  '--mainColor': '#fcc80e',
  '--darkColor': '#ebebeb',
  '--messageColor': '#999999',
  '--feedback-message-background': '#ffffff',
  '--unfold-menu-img': `url(${require('@/common/imgs/3d/unfold-light.png')})`,
  '--pack-up-menu-img': `url(${require('@/common/imgs/3d/pack-up.png')})`,
  '--close-drawer-img': `url(${require('@/common/imgs/3d/close-drawer-light.png')})`,
  '--help-icon': `url(${require('@/common/imgs/3d/help-icon-light.png')})`,
  '--feedback-tabs-active-left-theme-imgs': `url(${require('@/common/imgs/3d/left-active-light.png')})`,
  '--feedback-tabs-active-right-theme-imgs': `url(${require('@/common/imgs/3d/right-active-light.png')})`,
  '--view3d-init-notice-bgcolor': `#ffffff`,
  '--view3d-init-notice-left-bgcolor': `#ffffff`,
  '--view3d-init-notice-left-active-item': `#f9f9f9`,
  '--view3d-init-notice-message-content-border-color': `#ffffff`,
  '--view3d-init-notice-dhfxbg1': `url(${require('@/common/imgs/3d/view3d-notice-light-dhfx1.png')})`,
  '--view3d-init-notice-dhfxbg2': `url(${require('@/common/imgs/3d/view3d-notice-light-dhfx2.png')})`,
  '--view3d-init-notice-bg1': `url(${require('@/common/imgs/3d/view3d-notice-1-light.png')})`,
  '--view3d-init-notice-bg2': `url(${require('@/common/imgs/3d/view3d-notice-2-light.png')})`,
  '--view3d-init-notice-bg3': `url(${require('@/common/imgs/3d/view3d-notice-3-light.png')})`,
  '--view3d-close-notice': `url(${require('@/common/imgs/3d/close-notice-light.png')})`
}
var darkTheme = {
  "--textColor": '#ffffff',
  "--backgroundColor": '#454647',
  '--mainColor': '#fcc80e',
  '--darkColor': '#393b3c',
  '--messageColor': '#999999',
  '--feedback-message-background': '#393b3c',
  '--unfold-menu-img': `url(${require('@/common/imgs/3d/unfold.png')})`,
  '--pack-up-menu-img': `url(${require('@/common/imgs/3d/pack-up.png')})`,
  '--close-drawer-img': `url(${require('@/common/imgs/3d/close-drawer.png')})`,
  '--help-icon': `url(${require('@/common/imgs/3d/help-icon.png')})`,
  '--feedback-tabs-active-left-theme-imgs': `url(${require('@/common/imgs/3d/left-active.png')})`,
  '--feedback-tabs-active-right-theme-imgs': `url(${require('@/common/imgs/3d/right-active.png')})`,
  '--view3d-init-notice-bgcolor': `#38383A`,
  '--view3d-init-notice-left-bgcolor': `#323233`,
  '--view3d-init-notice-left-active-item': `rgba(255,255,255,0.04)`,
  '--view3d-init-notice-message-content-border-color': `#000000`,
  '--view3d-init-notice-bg1': `url(${require('@/common/imgs/3d/view3d-notice-1.png')})`,
  '--view3d-init-notice-bg2': `url(${require('@/common/imgs/3d/view3d-notice-2.png')})`,
  '--view3d-init-notice-bg3': `url(${require('@/common/imgs/3d/view3d-notice-3.png')})`,
  '--view3d-init-notice-dhfxbg1': `url(${require('@/common/imgs/3d/view3d-notice-dhfx1.png')})`,
  '--view3d-init-notice-dhfxbg2': `url(${require('@/common/imgs/3d/view3d-notice-dhfx2.png')})`,
  '--view3d-close-notice': `url(${require('@/common/imgs/3d/close-notice.png')})`
}

export {
  lightTheme,
  darkTheme
}